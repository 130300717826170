import React from "react";
import theme from "theme";
import { Theme, Text, Section, Link, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"sartlar-ve-kosullar"} />
		<Helmet>
			<title>
				Eclipse Events
			</title>
			<meta name={"description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:title"} content={"Eclipse Events"} />
			<meta property={"og:description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header1>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Şartlar ve Koşullar
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Bu Şartlar ve Koşullar, Eclipse Events'in sunduğu hizmetlerin kullanımına ilişkin kuralları ve sorumlulukları açıklar. Web sitemizi kullanarak veya hizmetlerimizden yararlanarak bu şartları kabul etmiş olursunuz.
			</Text>
		</Section>
		<Section padding="40px 0" sm-padding="20px 0">
			<Override slot="SectionContent" align-items="flex-start" />
			<Text as="h3" font="--headline3" margin="20px 0">
				1. Hizmetlerin Kullanımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Eclipse Events tarafından sunulan hizmetler, yalnızca yasal ve bu Şartlar ve Koşullar’a uygun olarak kullanılabilir. Hizmetlerimizi herhangi bir yasadışı faaliyet için kullanmanız yasaktır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				2. Rezervasyon ve Ödeme
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Hizmetlerimizin rezervasyonu ve ödemesi, belirlenen koşullara ve ücretlere tabidir. Rezervasyonunuzu onayladığınızda, belirtilen ücretleri ödemeyi kabul etmiş olursunuz. Ödemeler iade edilemez, ancak belirli durumlarda değişiklikler yapılabilir.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				3. İptal ve Değişiklik Politikası
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Rezervasyonunuzu iptal etmek veya değiştirmek isterseniz, belirlenen iptal politikalarımıza uymalısınız. İptaller belirli bir süre içinde yapılmazsa, ücretler iade edilmeyebilir.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				4. Sorumluluk Sınırlaması
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Eclipse Events, hizmetlerin kullanımından doğabilecek doğrudan, dolaylı veya rastlantısal zararlardan sorumlu tutulamaz. Hizmetlerimiz "olduğu gibi" ve "mevcut olduğu şekilde" sunulmaktadır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				5. Fikri Mülkiyet
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Web sitemizde ve hizmetlerimizde yer alan tüm içerikler, Eclipse Events'e veya lisans verenlerine aittir. Bu içeriklerin izinsiz kullanımı yasaktır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				6. Gizlilik
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Hizmetlerimizi kullanırken sağladığınız kişisel veriler, Gizlilik Politikamız kapsamında işlenir ve korunur.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				7. Yürürlük ve Değişiklikler
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Bu Şartlar ve Koşullar, web sitemizde yayınlandığı tarihten itibaren geçerlidir. Eclipse Events, bu şartları herhangi bir zamanda değiştirme hakkını saklı tutar. Değişiklikler, web sitemizde yayınlandığı tarihten itibaren geçerli olacaktır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				8. İletişim Bilgileri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Bu Şartlar ve Koşullar hakkında sorularınız varsa, lütfen aşağıdaki iletişim bilgileri üzerinden bizimle iletişime geçin:
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				**E-posta:** info@eventexcellence.com
			</Text>
		</Section>
		<Components.NewFooter>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Box min-width="100px" min-height="auto" color="--light" align-self="center">
				<Link
					href="/sartlar-ve-kosullar"
					color="--light"
					margin="0px 1rem 0px 0px"
					text-decoration-line="underline"
					font="--headline5"
				>
					Şartlar ve Koşullar
				</Link>
				<Link href="/gizlilik-politikasi" color="--light" text-decoration-line="underline" font="--headline5">
					Gizlilik Politikası
				</Link>
			</Box>
		</Components.NewFooter>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});